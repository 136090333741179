import * as types from '../constants/dataLayer';
import {
  setSearchData,
  getSearchLabel,
  setHomeSearchData,
  setProLeadData,
  getConnectionClickData
} from '../../utils/dataLayerHelper';

import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { getConfig } from '../../config/portal';
import { getBoatClassMappingForDataLayer } from '../../utils/classHelper';

const init = (params) => {
  return {
    type: types.DATALAYER_INIT,
    params
  };
};

const failure = (message) => {
  return {
    type: types.DATALAYER_FAILURE,
    message
  };
};

const registerEvent = (eventType, params) => {
  return {
    type: types.DATALAYER_EVENT,
    eventType,
    params
  };
};

const exitLinkImpression = (id, name, position) => {
  return {
    type: types.DATALAYER_EXIT_LINK_IMPRESSION,
    id,
    name,
    position
  };
};

const externalLinkImpression = (id, name, position) => {
  return {
    type: types.DATALAYER_EXTERNAL_LINK_IMPRESSION,
    id,
    name,
    position
  };
};

const productImpression = (
  imtId,
  listingType,
  position,
  page,
  container,
  region
) => {
  return {
    type: types.DATALAYER_PRODUCT_IMPRESSION,
    imtId,
    listingType,
    position,
    page,
    container,
    region
  };
};

const product = (productId, boatClass, boatMake, boatLength, state) => {
  return {
    type: types.DATALAYER_PRODUCT_ADD,
    productId,
    boatClass,
    boatMake,
    boatLength,
    state
  };
};

const productClick = (productId, listingType) => {
  return {
    type: types.DATALAYER_PRODUCT_CLICK,
    productId,
    listingType
  };
};

const premiumPlacementImpression = (
  imtId,
  name,
  position,
  creative,
  rank,
  page
) => {
  return {
    type: types.DATALAYER_PREMIUM_PLACEMENT_IMPRESSION,
    imtId,
    name,
    position,
    creative,
    rank,
    page
  };
};

const premiumPlacementClick = (imtId, name, position, creative, rank, page) => {
  return {
    type: types.DATALAYER_PREMIUM_PLACEMENT_CLICK,
    imtId,
    name,
    position,
    creative,
    rank,
    page
  };
};

const lead = (imtId, leadType) => {
  return {
    type: types.DATALAYER_ADD_LEAD,
    imtId,
    leadType
  };
};

const connectionClick = (imtId, leadType) => {
  return {
    type: types.DATALAYER_CONNECTION_CLICK,
    imtId,
    leadType
  };
};

const ListingView = (imtId) => {
  return {
    type: types.DATALAYER_LISTING_VIEW,
    imtId
  };
};

const searchCount = () => {
  return {
    type: types.DATALAYER_SEARCH_COUNT
  };
};

export const initDataLayer = (params) => (dispatch) => {
  const {
    description,
    country,
    portalName,
    section,
    brandID,
    subSection,
    pageName,
    detailedPageName,
    englishUrl
  } = params;
  try {
    get(
      window,
      `google_tag_manager['${get(
        getConfig(),
        'scripts.gtm.container'
      )}'].dataLayer.reset`,
      () => {}
    )();
    window.digitalData = window.digitalData || {};
    //Reset
    set(window.digitalData, 'page.attributes.productImpressions', []);
    set(window.digitalData, 'page.attributes.internalPromotionImpressions', []);
    set(window.digitalData, 'page.attributes.externalLinkImpressions', []);
    set(window.digitalData, 'page.attributes.exitLinkImpressions', []);
    set(window.digitalData, 'page.attributes.productClicks', []);
    set(window.digitalData, 'page.attributes.internalPromotionClicks', []);
    set(window.digitalData, 'page.attributes.externalLinkClicks', []);
    set(window.digitalData, 'page.attributes.exitLinkClicks', []);
    set(window.digitalData, 'user', []);
    set(window.digitalData, 'events', []);

    //Init
    window.digitalDataBuilder
      .init()
      .set('Site Description', description)
      .set('Site Country', country)
      .set('Portal Name', portalName)
      .set('Section', section)
      .set('Sub-Section', subSection)
      .set('Page Name', pageName)
      .set('Detailed Page Name', detailedPageName)
      .set('BG Source', 'react')
      .set('URL', englishUrl);

    if (brandID) {
      window.digitalDataBuilder.set('Brand Id', brandID);
    }

    dispatch(init(params));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const registerProductImpressions = () => (dispatch) => {
  setTimeout(() => {
    try {
      if (!window.digitalDataUpdates?.addProductImpression) {
        return;
      }

      let newDigitalData = JSON.parse(JSON.stringify(window.digitalData));
      newDigitalData.event = 'view_item_list';
      newDigitalData.ecommerce.items =
        window.digitalDataUpdates.addProductImpression;
      window.dataLayer.push(newDigitalData);
      delete window.digitalDataUpdates.addProductImpression;
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }, 0);
};

export const registerListingView = () => (dispatch) => {
  setTimeout(() => {
    try {
      if (!window.digitalDataUpdates?.setListingView) {
        return;
      }

      let newDigitalData = JSON.parse(JSON.stringify(window.digitalData));
      newDigitalData.event = 'view_item';
      newDigitalData.ecommerce.items = window.digitalDataUpdates.setListingView;
      window.dataLayer.push(newDigitalData);
      delete window.digitalDataUpdates.setListingView;
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }, 0);
};

export const registerPageView = (params) => (dispatch) => {
  setTimeout(() => {
    try {
      let newDigitalData = JSON.parse(JSON.stringify(window.digitalData));
      window.dataLayer.push({
        'event': 'pageview',
        ...newDigitalData
      });
      dispatch(registerEvent('pageview', params));
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }, 0);
  setTimeout(() => {
    try {
      window.digitalDataBuilder.addPageView();
      window.kameleoonHelper.kameleoonProcessGA4();
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }, 1000);
};

export const processKameleoonGoal = (goalName) => (dispatch) => {
  setTimeout(() => {
    try {
      window.kameleoonHelper.processCustomGoal(goalName);
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }, 0);
};

export const registerDownloadApp = (label, action) => (dispatch) => {
  setTimeout(() => {
    try {
      const event = {
        event: 'e_downloadApp',
        category: 'download_app',
        action: action,
        label: label
      };
      window.dataLayer.push(event);
      dispatch(registerEvent('e_downloadApp', event));
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }, 0);
};

export const addEvent = (type, params) => (dispatch) => {
  try {
    window.dataLayer.push({ 'event': type, ...params });
    dispatch(registerEvent(type, params));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const genericButtonClickEvent = (category, action, label, value) => {
  const eventParams = {
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    value: value
  };
  return addEvent('genericButtonClick', eventParams);
};

export const setExitLinkImpression = (id, name, position) => (dispatch) => {
  try {
    window.digitalDataBuilder.setExitLinkImpression(id, name, position);
    dispatch(exitLinkImpression(id, name, position));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setExternalLinkImpression = (id, name, position) => (dispatch) => {
  try {
    window.digitalDataBuilder.setExternalLinkImpression(id, name, position);
    dispatch(externalLinkImpression(id, name, position));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setListingClick = (id, listingType, location, model) => {
  try {
    window.digitalDataBuilder.addProductClick(
      id,
      listingType,
      '',
      '',
      '',
      location,
      model
    );
  } catch (err) {
    // This line was needed, console.error wasn't allowed by the linter, and no empty blocks are allowed.
  }
};

export const setProductImpression =
  (
    id,
    listingType,
    position,
    page,
    category,
    container = '',
    region = '',
    model = '',
    name = '',
    brand = ''
  ) =>
  (dispatch) => {
    try {
      const categoryValue = isNil(category) ? undefined : category;
      window.digitalDataBuilder.setProductImpression(
        id,
        listingType,
        categoryValue,
        position,
        page,
        container,
        region,
        model,
        name,
        brand
      );
      dispatch(
        productImpression(id, listingType, position, page, container, region)
      );
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  };

export const setProduct = (product) => (dispatch) => {
  try {
    let convertedClass = window.digitalDataBuilderPlatformMapper.convertClass(
      product.productClass
    );
    product.productClass = convertedClass;
    window.digitalData.product = [];
    window.digitalDataBuilder.setProduct(product);
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const addProduct =
  (productId, boatClass, boatMake, boatLength, state) => (dispatch) => {
    try {
      let convertedClass =
        window.digitalDataBuilderPlatformMapper.convertClass(boatClass);
      window.digitalDataBuilder.addProduct(
        productId,
        convertedClass,
        boatMake,
        boatLength,
        state
      );
      dispatch(product(productId, boatClass, boatMake, boatLength, state));
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  };

export const setListingView = (productId) => (dispatch) => {
  try {
    window.digitalDataUpdates.setListingView = productId;
    dispatch(ListingView(productId));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const addProductClick = (productId, listingType) => (dispatch) => {
  try {
    window.digitalDataBuilder.addProductClick(productId, listingType);
    dispatch(productClick(productId, listingType));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const addLead = (imtId, leadType) => (dispatch) => {
  try {
    window.digitalDataBuilder.addLead(imtId, leadType);
    let connectionClickData = getConnectionClickData(imtId, leadType);
    window.digitalDataBuilder.addConnectionClick(connectionClickData);
    dispatch(lead(imtId, leadType));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const proLead = (data) => {
  return {
    type: types.DATALAYER_PRO_LEAD,
    data: data
  };
};

export const addProLead = (data) => (dispatch) => {
  if (!isEmpty(data)) {
    let leadData = setProLeadData(data);
    try {
      window.digitalDataBuilder.tagManager.track('PRO_LEAD', leadData);
      let connectionClickData = getConnectionClickData(data.partyId, data.id);
      window.digitalDataBuilder.addConnectionClick(connectionClickData);
      dispatch(proLead(leadData));
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }
};

export const setConnectionClick = (imtId, leadType) => (dispatch) => {
  try {
    let connectionClickData = getConnectionClickData(imtId, leadType);
    window.digitalDataBuilder.addConnectionClick(connectionClickData);
    dispatch(connectionClick(imtId, leadType));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const customVariable = (data) => {
  return {
    type: types.DATALAYER_CUSTOM_VARIABLE,
    data: data
  };
};

export const addEcommerceTracking =
  (productId, listingType = 'enhanced listing') =>
  (dispatch) => {
    let data = {
      clicks: {
        actionField: { list: listingType },
        products: { id: productId }
      }
    };
    try {
      window.digitalDataBuilder.addCustomVariable('ecommerce', data);
      dispatch(customVariable(data));
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  };

const searchHomeTracking = (data) => {
  return {
    type: types.DATALAYER_SEARCH,
    data: data
  };
};

export const setHomeSearchTracking = (data) => (dispatch) => {
  if (!isEmpty(data)) {
    let trackData = setHomeSearchData(data);
    try {
      window.digitalDataBuilder.tagManager.track('SEARCH', trackData);
      dispatch(searchHomeTracking(trackData));
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }
};

const genericEvent = (data) => {
  return {
    type: types.DATALAYER_GENERIC_EVENT,
    data: data
  };
};

const clearEcommerce = () => {
  return {
    type: types.DATALAYER_CLEAR_ECOMMERCE
  };
};

export const clearEcommerceData = () => (dispatch) => {
  try {
    window.dataLayer.push({ ecommerce: null });
    dispatch(clearEcommerce());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setGenericEvent =
  (category, action, label = '', value, eventName = '', custom = '') =>
  (dispatch) => {
    const formattedLabel = getBoatClassMappingForDataLayer(label);
    let event = {
      category: category,
      action: action,
      label: formattedLabel,
      value: value,
      eventName,
      ...(custom && { custom })
    };
    try {
      window.digitalDataBuilder.addGenericEvent(event);
      dispatch(genericEvent(event));
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  };

const enhancedEcommerce = (productIdArray, action) => {
  return {
    type: types.DATALAYER_ECOMMERCE,
    productIdArray,
    action
  };
};

export const setEnhancedEcommerce = (productIdArray, action) => (dispatch) => {
  try {
    window.digitalDataBuilder.addEnhancedEcommerce(productIdArray, action);
    dispatch(enhancedEcommerce(productIdArray, action));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setPPImpression =
  (imtId, name, position, creative, rank, page) => (dispatch) => {
    try {
      window.digitalDataBuilder.setInternalPromotionImpression({
        imtId,
        name,
        position,
        creative,
        rank,
        page
      });
      dispatch(
        premiumPlacementImpression(imtId, name, position, creative, rank, page)
      );
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  };

export const setPPClick =
  (imtId, name, position, creative, rank, page) => (dispatch) => {
    try {
      window.digitalDataBuilder.addInternalPromotionClick({
        imtId,
        name,
        position,
        creative,
        rank,
        page
      });
      dispatch(
        premiumPlacementClick(imtId, name, position, creative, rank, page)
      );
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  };

const collapseTracking = (data) => {
  return {
    type: types.DATALAYER_COLLAPSE_TRACKING,
    data: data
  };
};

export let setCollapseContentTracking = (data) => (dispatch) => {
  try {
    window.digitalDataBuilder.tagManager.track('COLLAPSE_CONTENT', {
      'content': data
    });
    dispatch(collapseTracking(data));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const changeCarouselImageTracking = () => {
  return {
    type: types.DATALAYER_CAROUSEL_CHANGE_ITEM
  };
};

export const changeCarouselImage = () => (dispatch) => {
  try {
    window.digitalDataBuilder.tagManager.track('CAROUSEL_NAVIGATION');
    dispatch(changeCarouselImageTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const clickCarouselThumbnailTracking = () => {
  return {
    type: types.DATALAYER_CAROUSEL_THUMBNAIL_CLICK
  };
};

export const clickCarouselThumbnail = () => (dispatch) => {
  try {
    window.digitalDataBuilder.tagManager.track('CAROUSEL_THUMBNAIL');
    dispatch(clickCarouselThumbnailTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const openCarouselModalTracking = () => {
  return {
    type: types.DATALAYER_CAROUSEL_THUMBNAIL_CLICK
  };
};

export const openCarouselModal = () => (dispatch) => {
  try {
    window.digitalDataBuilder.tagManager.track('CAROUSEL_MODAL_OPEN');
    dispatch(openCarouselModalTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const contactFormSubmitTracking = () => {
  return {
    type: types.DATALAYER_CONTACTFORM_SUBMIT
  };
};

export const trackPhoneLead = () => (dispatch) => {
  try {
    window.digitalDataBuilder.addPhoneLead();
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const trackContactFormSubmit = (data) => (dispatch) => {
  try {
    window.digitalDataBuilder.tagManager.track('CONTACTFORM_SUBMIT', data);
    dispatch(contactFormSubmitTracking());
    window.digitalDataBuilder.addEmailLead();
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const trackContactManufacturerFormSubmit = (leadId) => (dispatch) => {
  try {
    window.digitalDataBuilder.tagManager.track('CONTACTFORM_SUBMIT', {
      'manufacturer': 'contact manufacturer',
      'leadId': leadId
    });
    dispatch(contactFormSubmitTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const contactFormOpenTracking = () => {
  return {
    type: types.DATALAYER_CONTACTFORM_OPEN
  };
};

export const trackContactFormOpen = () => (dispatch) => {
  try {
    window.digitalDataBuilder.tagManager.track('CONTACTFORM_OPEN');
    dispatch(contactFormOpenTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const phoneCallTracking = () => {
  return {
    type: types.DATALAYER_PHONE_CALL
  };
};

export const trackPhoneCall = () => (dispatch) => {
  try {
    window.digitalDataBuilder.tagManager.track('PHONE_CALL');
    dispatch(phoneCallTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const phoneNumberReveal = () => {
  return {
    type: types.DATALAYER_PHONE_REVEAL
  };
};

export const trackPhoneNumberReveal = () => (dispatch) => {
  try {
    window.digitalDataBuilder.tagManager.track('PHONE_REVEAL');
    dispatch(phoneNumberReveal());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const socialMediaTracking = () => {
  return {
    type: types.DATALAYER_SOCIAL_MEDIA
  };
};

export const trackSocialMedia =
  (socialNetwork, socialAction, socialTarget) => (dispatch) => {
    try {
      window.digitalDataBuilder.tagManager.track('SOCIAL_MEDIA', {
        socialNetwork,
        socialAction,
        socialTarget
      });
      dispatch(socialMediaTracking());
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  };

export const setSoftLeadEvent = (uniqueId) => (dispatch) => {
  try {
    window.digitalDataBuilder.addSoftLead(uniqueId);
    dispatch(softLeadEvent(uniqueId));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const softLeadEvent = (data) => ({
  type: types.DATALAYER_SOFT_LEAD_EVENT,
  data: data
});

export const trackSearchCount =
  (count = 0) =>
  (dispatch) => {
    try {
      window.digitalDataBuilder.set('Page Search Results Count', count);
      dispatch(searchCount());
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  };

const searchTracking = (data) => {
  return {
    type: types.DATALAYER_SEARCH_FILTER,
    data: data
  };
};

export const setSearchTracking =
  (data, searchType = 'single', action) =>
  (dispatch) => {
    if (!isEmpty(data)) {
      let trackData = setSearchData(data, searchType);
      trackData.label = getSearchLabel(trackData);
      if (action) {
        trackData.action = action;
      }
      try {
        window.digitalDataBuilder.tagManager.track('SEARCH_FILTER', trackData);
        dispatch(searchTracking(trackData));
      } catch (e) {
        dispatch(failure(e.toString()));
      }
    }
  };

// We do not need redux for anything
export const setSearchTrackingClean = (data, searchType = 'single', action) => {
  if (!isEmpty(data)) {
    let trackData = setSearchData(data, searchType);
    trackData.label = getSearchLabel(trackData);
    if (action) {
      trackData.action = action;
    }
    try {
      window.digitalDataBuilder.tagManager.track('SEARCH_FILTER', trackData);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('setSearchTrackingClean error', e);
    }
  }
};

export const setGenericEventClean = (category, action, label = '', value, eventName = '', custom = '') => {
  const formattedLabel = getBoatClassMappingForDataLayer(label);
  let event = {
    category: category,
    action: action,
    label: formattedLabel,
    value: value,
    eventName,
    ...(custom && { custom })
  };
  try {
    window.digitalDataBuilder.addGenericEvent(event);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('setGenericEvent error', e);
  }
};

const navigationTracking = (data) => {
  return {
    type: types.DATALAYER_NAVIGATION_CLICK,
    data: data
  };
};

export const setNavigationEvent = ({ action, label, location }) => (dispatch) => {
  let pageData = {};
  if (window.digitalData?.ga4?.page) {
    /* eslint-disable camelcase */
    const { portal, site_region, section, subsection, detailed_page_name, are_ads_blocked } = window.digitalData.ga4.page;
    pageData = {
      portal,
      site_region,
      section,
      subsection,
      detailed_page_name,
      are_ads_blocked
    };
  }

  const trackData = {
    ga4: {
      page: {
        menu: action,
        menu_item: label,
        menu_location: location,
        ...pageData
      }
    }
  };

  try {
    window.digitalDataBuilder.tagManager.track('NAVIGATION_CLICK', trackData);
    dispatch(navigationTracking(trackData));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('setNavigationEvent error', e);
    dispatch(failure(e.toString()));
  }
};
