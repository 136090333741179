import {createI18nContainer} from './translations/intlManager';
import {pathNameFromHistory} from './crossEnvHelpers';
import {getLanguageFromUrl} from './translations/helpers';
import * as utils from '../utils';
import {getDefaultTranslation, loadFormattedLanguages} from './translations/translationsLoader';

/**
 * On server we store all translations on load time in this object.
 * On client we use it for now, but we could avoid it.
 * @type {[key:string]:string}
 */
let translationDictionary = {};

// needed for tests
export const getTranslationDictionary = () => translationDictionary;
export const setTranslationDictionary = (newDictionary) => {
  translationDictionary = newDictionary;
};

export const startLanguagesServerSide = (allTranslations) => {
  if (utils.isServer()) {
    translationDictionary = allTranslations;
    return true;
  }
  return false;
};

const loadNewFormattedLanguages = async (langCode, config, store) => {
  if (store !== false) {
    store = true;
  }
  const theTranslation = await loadFormattedLanguages(langCode, config);
  if (store) {
    translationDictionary[langCode] = theTranslation;
  }
  return theTranslation;
};

const getTranslationByLanguage = async (langCode, config, store) => {
  if (store !== false) {
    store = true;
  }
  const theTranslation = store && translationDictionary[langCode] || await loadNewFormattedLanguages(langCode, config, store);
  return theTranslation;
};

const assertConfig = (config) => {
  if (!(config.name) || !config.host) {
    throw new Error('A proper config is required');
  }
};

const assertI18n = (i18n) => {
  if (!i18n.intl) {
    throw new Error('I18n object is not defined');
  }
};

const languageFromLocationOrHost = (location, host, config) => {
  const thePathName = pathNameFromHistory(location);
  const ahost = host || '';
  const languageProps = getLanguageFromUrl(thePathName, ahost, config);

  return {
    language: languageProps.language,
    pathLanguage: languageProps.pathLanguage || '/'
  };
};

const createLanguageService = async (configService, pathname, host, defaultLangDict = true) => {
  let translationMessages = defaultLangDict ? translationDictionary : {};
  let i18n = {};
  let pageType = '';
  const config = configService.getConfig();
  let {language: urlLanguage, pathLanguage} = getLanguageFromUrl(pathname, host, config);
  let langHost = host;
  // we always need English
  const baseLang = config.language;
  translationMessages[baseLang] = await getTranslationByLanguage(baseLang, config, defaultLangDict);
  createI18nContainer(baseLang, '/', config, translationMessages, langHost);

  /**
   * Start i18n for a language
   * @param langCode the language code
   * @param newHost the host to use
   * @returns {Promise<IntlObject>}
   */
  const startI18nForLanguage = async (langCode, newHost) => {
    assertConfig(config);
    langHost = newHost || host;
    translationMessages[langCode] = await getTranslationByLanguage(langCode, config, defaultLangDict);
    i18n  = createI18nContainer(urlLanguage, pathLanguage, config, translationMessages, langHost);
    pageType = i18n.boatsConstants.getPageTypeFromPath(pathLanguage, i18n.intl.formatMessage);
    return i18n;
  };

  /**
   * Update i18n when language changes
   * @param newLangCode the new language code
   * @param newHost the host to use
   * @param newPathLanguage the language from the path
   * @returns {Promise<IntlObject>}
   */
  const updateI18nByLanguage = async (newLangCode, newHost, newPathLanguage) => {
    urlLanguage = newLangCode || urlLanguage;
    pathLanguage = newPathLanguage === '' ? '/' : newPathLanguage || pathLanguage;
    return await startI18nForLanguage(newLangCode, newHost);
  };

  /**
   * Update i18n when location object changes
   * @param location the location object from DOM or server side
   * @param newHost the host to use
   * @returns {Promise<IntlObject>}
   */
  const updateI18nByLocation = async (location, newHost) => {
    const updatedLang = languageFromLocationOrHost(location, newHost, config);
    // istambul ignore next
    if (updatedLang.language === urlLanguage && i18n?.host === newHost) {
      return i18n;
    }
    urlLanguage = updatedLang.language;
    pathLanguage = updatedLang.pathLanguage;
    return await startI18nForLanguage(urlLanguage, newHost);
  };

  // TODO: Create test for this and clean the code to not repeat stuff
  // only safe to call it on server side render
  const updateI18nByLocationSync = (location, newHost) => {
    const updatedLang = languageFromLocationOrHost(location, newHost, config);
    urlLanguage = updatedLang.language;
    pathLanguage = updatedLang.pathLanguage;
    i18n  = createI18nContainer(urlLanguage, pathLanguage, config, translationMessages, newHost);
    pageType = i18n.boatsConstants.getPageTypeFromPath(pathLanguage, i18n.intl.formatMessage);
    return i18n;
  };

  i18n = await startI18nForLanguage(urlLanguage);
  // Utility methods for the service
  const getI18n = () => {
    assertI18n(i18n);
    return i18n;
  };
  const languageFromUrl = (pathname, host) => getLanguageFromUrl(pathname, host, config);
  const getLanguage = () => urlLanguage;
  const getHost = () => i18n.host;
  const getPathLanguage = () => pathLanguage;
  const getCurrentLocale = () => i18n?.intl?.locale;
  const getOwnConfigService = () => configService;
  const getTranslationMessages = (locale) => translationMessages[locale];
  const getPageType = () => pageType;
  const allServiceTranslations = () => {
    // should we return cloneDeep(translationMessages)??
    return translationMessages;
  };
  const loadTranslationMessages = async (locale) => {
    if (!translationMessages[locale]) {
      translationMessages[locale] = await getTranslationByLanguage(locale, config, defaultLangDict);
    }
    return translationMessages[locale];
  };
  const removeTranslation = (locale) => {
    delete translationMessages[locale];
  };
  const formatMessage = () => {
    assertI18n(i18n);
    return i18n.intl.formatMessage;
  };
  const getRouteConstants = () => i18n.routesConstants;
  const getBoatsConstants = () => i18n.boatsConstants;
  const loadFormattedLanguage = async (langCode) => await getTranslationByLanguage(langCode, config, defaultLangDict);
  const defaultTranslation = () => getDefaultTranslation();
  const languageService = {
     // Parses the url and detects the language
    languageFromUrl,
    // Returns the current intl object
    getI18n,
    // loads and formats a language asyncrhonously
    loadFormattedLanguage,
    // returns all stored formatted translation
    allServiceTranslations,
    // loads and formats a language asyncrhonously and stores it
    loadTranslationMessages, getTranslationByLanguage,
    // helper to get the page type
    getPageType,
    // gets the current host we have in the intl object
    getHost,
    // function formatMessage from intl object
    formatMessage,
    // changes the current intl object if we pass another language
    updateI18nByLanguage,
    // changes the current intl object if we pass another location
    updateI18nByLocation,
    // gets the current language
    getLanguage,
    // gets the current locale
    getCurrentLocale,
    // gets the translation messages for a given locale
    getTranslationMessages,
    // gets the current path language
    getPathLanguage,
    // gets the current config service we are using with this language service
    getOwnConfigService,
    // gets the route constants as parsed from the intl object. Used to be called rts()
    getRouteConstants,
    // gets the boats constants as parsed from the intl object. Used to be called bts()
    getBoatsConstants,
    // removes a translation from the service
    removeTranslation,
    // sync version of updateI18nByLocation to work on Server Side Render
    updateI18nByLocationSync,
    // return the default translation
    defaultTranslation
  };
  const empty = () => {
    for (let key in languageService) {
      languageService[key] = null;
    }
  };
  languageService.empty = empty;

  return languageService;
};

export { createLanguageService, assertConfig, assertI18n };
