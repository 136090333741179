import get from 'lodash/get';
import { getConfig } from '../config/portal';
import {
  getCurrentCurrency,
  getCurrentUom
} from './language';
import { syncTranslationElements } from '../tppServices/translations/intlManager';

export const getCurrency = () => {
  const currentCurrency = getCurrentCurrency();
  const currentCurrencyCode = get(currentCurrency, 'currency.code');
  return currentCurrencyCode || getConfig().currency.abbr;
};

export const getCurrencyName = () => {
  const currentCurrency = getCurrentCurrency();
  const currentCurrencyName = get(currentCurrency, 'currency.name');
  return currentCurrencyName || getConfig().currency.name;
};

export const getCurrencySymbol = (currency) => {
  const currentCurrency = getCurrentCurrency(currency);
  const currentCurrencySymbol = get(currentCurrency, 'currency.symbol');
  return currentCurrencySymbol || getConfig().currency.symbol;
};

export const getCurrencyCode = () => {
  const currentCurrency = getCurrentCurrency();
  const currentCurrencyCode = get(currentCurrency, 'currency.code');
  return currentCurrencyCode || getConfig().currency.abbr;
};

export const getCurrencyFormat = (currency) => {
  const currentCurrency = getCurrentCurrency(currency);
  const currentCurrencyFormat = get(
    currentCurrency,
    'formatingOptions.symbolOrder'
  );
  return currentCurrencyFormat || getConfig().currency.format;
};

export const getLength = (customUom, cfg) => {
  if (customUom) {
    return customUom.abbr;
  }
  // We are going to remove  calls to getConfig like this. No point in covering them.
  /* istanbul ignore next */
  const config = cfg || getConfig();
  const currentUom = getCurrentUom();
  const currentLengthAbbr = get(currentUom, 'length.abbr');
  return currentLengthAbbr || config?.uom?.length?.abbr;
};

export const getLengthName = (customUom) => {
  if (customUom) {
    return customUom.name;
  }
  const currentUom = getCurrentUom();
  const currentLengthName = get(currentUom, 'length.name');
  return currentLengthName || getConfig().uom.length.name;
};

export const getLengthSymbol = (customUom) => {
  if (customUom) {
    return customUom.symbol;
  }
  const currentUom = getCurrentUom();
  const currentLengthSymbol = get(currentUom, 'length.symbol');
  return currentLengthSymbol || getConfig().uom.length.symbol;
};

export const getWeight = () => {
  return getConfig().uom.weight.abbr;
};

export const getWeightName = () => {
  return getConfig().uom.weight.name;
};

export const getWeightSymbol = () => {
  return getConfig().uom.weight.symbol;
};

export const getSpeed = () => {
  return getConfig().uom.speed.abbr;
};

export const getSpeedName = () => {
  return getConfig().uom.speed.name;
};

export const getSpeedSymbol = () => {
  return getConfig().uom.speed.symbol;
};

export const getCapacity = () => {
  return getConfig().uom.capacity.abbr;
};

export const getCapacityName = () => {
  return getConfig().uom.capacity.name;
};

export const getCapacitySymbol = () => {
  return getConfig().uom.capacity.symbol;
};

export const getDistance = () => {
  return getConfig().uom.distance.abbr;
};

export const getDistanceName = () => {
  return getConfig().uom.distance.name;
};

export const getDistanceSymbol = () => {
  return getConfig().uom.distance.symbol;
};

export const getRadius = () => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const radiusSearch = get(getConfig(), 'supports.radiusSearch.enabled', false);
  if (radiusSearch) {
    return t(messages.uom.radius.abbr);
  }
  return getConfig().uom.radius.abbr;
};

export const getRadiusName = () => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const radiusSearch = get(getConfig(), 'supports.radiusSearch.enabled', false);
  if (radiusSearch) {
    return t(messages.uom.radius.name);
  }
  return getConfig().uom.radius.name;
};

export const getRadiusItemAll = () => {
  const { formatMessage: t, messages } = syncTranslationElements();
  return t(messages.all);
};

export const getRadiusSymbol = () => {
  return getConfig().uom.radius.symbol;
};

export const getLocale = () => {
  return getConfig().locale;
};

export const getCustomUom = (
  isDefaultLocaleUom,
  boatDetailsCookie,
  supportedUoms,
  defaultLocaleUom
) => {
  if (
    isDefaultLocaleUom &&
    boatDetailsCookie &&
    boatDetailsCookie.get &&
    boatDetailsCookie.get('uom')
  ) {
    return supportedUoms[boatDetailsCookie.get('uom')];
  } else if (isDefaultLocaleUom && boatDetailsCookie && boatDetailsCookie.uom) {
    return supportedUoms[boatDetailsCookie.uom];
  } else if (isDefaultLocaleUom && supportedUoms && defaultLocaleUom) {
    return supportedUoms[defaultLocaleUom];
  }
};
