import get from 'lodash/get';
import { createIntl } from 'react-intl';

import { getConfig } from '../../config/portal';
import { getMessages } from '../../tppServices/translations/messages';

const locale = 'en';
export const createBoatLoanConstants = (config) => {
  // istambul ignore next
  if (!config) {
    config = getConfig();
  }
  const messages = getMessages();
  const intl = createIntl({ locale, messages });
  const { formatMessage: t } = intl;
  const SEO_TITLE = t(messages.boatLoansContent.SEO.title);
  const SEO_DESCRIPTION = t(messages.boatLoansContent.SEO.description);
  const RATE_DISCLAIMER = t(messages.boatLoansContent.banner.rates.disclaimer);
  const perk1 = t(messages.boatLoansContent.banner.perk1);
  const perk2 = t(messages.boatLoansContent.banner.perk2);
  const perk3 = t(messages.boatLoansContent.banner.perk3);
  const perk4 = t(messages.boatLoansContent.banner.perk4);
  const perk5 = t(messages.boatLoansContent.banner.perk5);
  const PERKS_LIST = [perk1, perk2, perk3, perk4, perk5];
  // eslint-disable-next-line max-len
  const CALCULATOR_SMALL_PRINT = `${RATE_DISCLAIMER} ${t(messages.boatLoansContent.calculator.results.disclaimer)}`;
  const TRIDENT_API_URL = get(config, 'boatLoans.tridentApi');

  const BOAT_LOANS = 'yacht-loans';

  const CDN_URL = `${get(config, 'client.cdn.endpoint')}`;
  const RATES_TRIDENT_API_URL = `${TRIDENT_API_URL}/rates/0/0/Boat?loanType=new`;
  const LEADS_TRIDENT_API_URL = `${TRIDENT_API_URL}/lead`;
  const CALCULATOR_TRIDENT_API_URL = `${TRIDENT_API_URL}/rates-calculator/0/0/Boat`;

  // eslint-disable-next-line max-len, no-restricted-syntax
  const TRIDENT_APP_URL = '/yacht-loans/apply/?purpose=Boat&source=101442&utm_source=yachtworld&utm_medium=internalreferral';
  const REACT_APP_PRIVACY_POLICY_URL = 'https://www.boatsgroup.com/privacy/';

  const HERO_BACKGROUND = `${CDN_URL}${get(config, 'boatLoans.assets.banner')}`;
  const BOAT_LOANS_CALCULATOR_BANNER = `${CDN_URL}${get(config, 'boatLoans.assets.loanCalculatorBanner')}`;
  const GREEN_CHECKMARK_ICON = `${CDN_URL}${get(config, 'boatLoans.assets.greenCheckmark')}`;
  const YW_TRIDENT_LOGOS = `${CDN_URL}${get(config, 'boatLoans.assets.ywTridentLogos')}`;
  const MEDAL = `${CDN_URL}${get(config, 'boatLoans.assets.medal')}`;
  const HANDS = `${CDN_URL}${get(config, 'boatLoans.assets.hands')}`;
  const STOPWATCH = `${CDN_URL}${get(config, 'boatLoans.assets.stopwatch')}`;
  const BOAT = `${CDN_URL}${get(config, 'boatLoans.assets.boat')}`;
  const BOAT_PICTURE = `${CDN_URL}${get(config, 'boatLoans.assets.boatGetPreQualified')}`;
  const CLOSE_ICON = `${CDN_URL}${get(config, 'boatLoans.assets.closeIcon')}`;
  const YACHTWORLD_LOGO = `${CDN_URL}${get(config, 'boatLoans.assets.yachtWorldLogo')}`;
  const TRIDENT_LOGO = `${CDN_URL}${get(config, 'boatLoans.assets.tridentLogo')}`;
  const CHECK_CIRCLE = `${CDN_URL}${get(config, 'boatLoans.assets.checkCircle')}`;
  const MONTHLY_PAYMENT_CALCULATOR = 'monthlyPaymentCalculator';
  const LOAN_AMOUNT_CALCULATOR = 'loanAmountCalculator';
  // eslint-disable-next-line no-restricted-syntax
  const CTA_APPLY_NOW_YACHT_LOANS_CALCULATOR_PAGE = '/yacht-loans/apply/?purpose=Boat&source=101442&utm_source=yachtworld&utm_medium=internalreferral&utm_campaign=yachtloancalculator';

  // BDP Yacht Loans Section
  const GREEN_STAR = `${CDN_URL}${get(config, 'boatLoans.assets.greenStar')}`;
  const ORANGE_STAR = `${CDN_URL}${get(config, 'boatLoans.assets.orangeStar')}`;
  const GOOGLE_ICON = `${CDN_URL}${get(config, 'boatLoans.assets.googleIcon')}`;
  const EXTERNAL_LINK = `${CDN_URL}${get(config, 'boatLoans.assets.externalLink')}`;
  const BOAT_SMALL = `${CDN_URL}${get(config, 'boatLoans.assets.boatSmall')}`;
  const BT_FINANCING_LOGO = `${CDN_URL}${get(config, 'boatLoans.assets.btFinancingLogo')}`;
  const TRIDENT_FAQ_LOGO = `${CDN_URL}${get(config, 'boatLoans.assets.tridentFaqLogo')}`;
  const MAX_AGE_TO_PREQUALIFY = 20;
  const MIN_PRICE_TO_PREQUALIFY = 150000;
  // eslint-disable-next-line no-restricted-syntax
  const CTA_PREQUAL_LINK_ROOT = '/yacht-loans/apply/pre-qualify/enter/?purpose=Boat&source=101442&utm_source=yachtworld&utm_medium=internalreferral';
  const CTA_APPLY_NOW_LINK_ROOT = '/yacht-loans/apply/?purpose=Boat&source=101442&utm_source=yachtworld&utm_medium=internalreferral';

  // SRP
  const PAYMENT_MODAL_DESKTOP = `${CDN_URL}${get(config, 'boatLoans.assets.paymentModalDesktop')}`;
  const PAYMENT_MODAL_MOBILE = `${CDN_URL}${get(config, 'boatLoans.assets.paymentModalMobile')}`;
  const PAYMENT_INFO_ICON = `${CDN_URL}${get(config, 'boatLoans.assets.paymentInfoIcon')}`;
  const CALCULATOR_IMG = `${CDN_URL}${get(config, 'boatLoans.assets.calculator')}`;
  // eslint-disable-next-line no-restricted-syntax
  const CTA_APPLY_NOW_CALCULATOR_SRP = '/yacht-loans/apply/?purpose=Boat&source=101442&utm_source=yachtworld&utm_medium=internalreferral&utm_campaign=SRPcalculatorapply';
  const MAX_AGE_TO_FINANCE = 20;
  const MIN_PRICE_TO_FINANCE = 25000;
  const MAX_PRICE_TO_FINANCE = 5000000;
  // return everything declared inside an object:
  return {
    SEO_TITLE,
    SEO_DESCRIPTION,
    RATE_DISCLAIMER,
    PERKS_LIST,
    CALCULATOR_SMALL_PRINT,
    TRIDENT_API_URL,
    BOAT_LOANS,
    CDN_URL,
    RATES_TRIDENT_API_URL,
    LEADS_TRIDENT_API_URL,
    CALCULATOR_TRIDENT_API_URL,
    TRIDENT_APP_URL,
    REACT_APP_PRIVACY_POLICY_URL,
    HERO_BACKGROUND,
    BOAT_LOANS_CALCULATOR_BANNER,
    GREEN_CHECKMARK_ICON,
    YW_TRIDENT_LOGOS,
    MEDAL,
    HANDS,
    STOPWATCH,
    BOAT,
    BOAT_PICTURE,
    CLOSE_ICON,
    YACHTWORLD_LOGO,
    TRIDENT_LOGO,
    CHECK_CIRCLE,
    MONTHLY_PAYMENT_CALCULATOR,
    LOAN_AMOUNT_CALCULATOR,
    CTA_APPLY_NOW_YACHT_LOANS_CALCULATOR_PAGE,
    GREEN_STAR,
    ORANGE_STAR,
    GOOGLE_ICON,
    EXTERNAL_LINK,
    BOAT_SMALL,
    BT_FINANCING_LOGO,
    TRIDENT_FAQ_LOGO,
    MAX_AGE_TO_PREQUALIFY,
    MIN_PRICE_TO_PREQUALIFY,
    PAYMENT_MODAL_DESKTOP,
    PAYMENT_MODAL_MOBILE,
    PAYMENT_INFO_ICON,
    CALCULATOR_IMG,
    CTA_APPLY_NOW_CALCULATOR_SRP,
    MAX_AGE_TO_FINANCE,
    MIN_PRICE_TO_FINANCE,
    MAX_PRICE_TO_FINANCE,
    CTA_PREQUAL_LINK_ROOT,
    CTA_APPLY_NOW_LINK_ROOT
  };
};

const initBoatLoanConstants = () => {
  let boatConstants;
  const makeBoatLoanConstants = config => {
    boatConstants = createBoatLoanConstants(config);
    return boatConstants;
  };
  const getBoatLoanConstants = (config) => {
    return boatConstants || makeBoatLoanConstants(config);
  };
  return getBoatLoanConstants;
};
const getBoatLoanConstants = initBoatLoanConstants();
export { getBoatLoanConstants };

