import {createIntl, createIntlCache} from 'react-intl';
import getTld from 'tld.js';
import {getRouteConstants} from '../../config/routes';
import {getBoatsConstants} from '../../constants/boats';

import {assertExists, getFormatMessageFunction} from '../tppDi';
import {toValidUrl} from '../../utils/urlHelpers/location';
import { getMessages } from './messages';

const getDefaultLocale = (config) => {
  // eslint-disable-next-line no-console
  console.assert(!!config, 'config is required');
  return config?.locale;
};

const getDefaultLangCode = (config) => {
  // eslint-disable-next-line no-console
  console.assert(!!config, 'config is required');
  return config?.language;
};

export const syncTranslationElements = (i18Service) => {
  const messages = getMessages();
  const formatMessage = getFormatMessageFunction(i18Service);
  assertExists(formatMessage);
  const t = formatMessage;
  return { t, messages, formatMessage };
};

const onTranslationError = (err) => {
  // istanbul ignore next
  if (process.env.NODE_ENV === 'development' && process.env.LOG_TRANSLATION_ERRORS) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

// eslint-disable-next-line func-style
function IntlManagerStart() {
  let tempIntlContainers = {};
  const temporaryI18nContainer = (locale, config, translationMessages, theHost = '', store = false) => {
    if (tempIntlContainers[locale]) {
      return tempIntlContainers[locale];
    }
    if (!config) {
      throw new Error('config is required');
    }
    const DEFAULT_LANGUAGE_CODE = getDefaultLangCode(config);
    const langsConfigs = config.languages;
    let tempIntl = createIntl({
      locale: locale,
      messages: translationMessages[locale],
      onError: onTranslationError
    });
    let addPathname = locale !== DEFAULT_LANGUAGE_CODE ? `/${locale}` : '';
    addPathname =
      langsConfigs[locale].type === 'tld' ||
      langsConfigs[locale].type === 'subdomain' ||
      langsConfigs[locale].type === 'domain' ||
      (langsConfigs[locale].type === 'domainFolder' &&
        langsConfigs[locale].url === 'root')
        ? ''
        : addPathname;
    // localhost support
    if (getTld(theHost) === '') {
      addPathname = locale !== DEFAULT_LANGUAGE_CODE ? `/${locale}` : '';
    }

    const routes = getRouteConstants(tempIntl, addPathname, config);
    const boats = getBoatsConstants(routes, addPathname);
    const tempIntlContainer  = {
      intl: tempIntl,
      routes: routes,
      boats: boats,
      addPathname: addPathname
    };
    if (store) {
      tempIntlContainers[locale] = tempIntlContainer;
    }
    return tempIntlContainer;
  };

  const createI18nContainer = (locale, pathLocale, config, translationMessages, host = '') => {
    if (!config) {
      throw new Error('config is required to create I18nContainer');
    }
    let cache = createIntlCache();
    const PATHS = ['/', ''];
    let addPathnameLocale =
      PATHS.includes(pathLocale) || !config.supports.multiLanguage
        ? ''
        : `/${pathLocale}`;
    let intl = createIntl(
      {
        locale: locale,
        messages: translationMessages[locale],
        // TODO: use real on translation error handler
        onError: onTranslationError
      },
      cache
    );

    let localizedRoutes = getRouteConstants(intl, addPathnameLocale, config);
    let localizedBoats = getBoatsConstants(localizedRoutes, addPathnameLocale);

    let url;
    if (host) {
      url = toValidUrl(host);
    }
    const I18nContainer = {
      intl: intl,
      // duplicated because of different usages in the portal. It should be cleaned up
      routesConstants: localizedRoutes,
      boatsConstants: localizedBoats,
      routes: localizedRoutes,
      boats: localizedBoats,
      addPathnameLocale,
      host: url ? url.protocol + '//' + url.host : config.host
    };
    // Main I18nContainer is also stored in tempIntlContainers to allow for pageType change
    tempIntlContainers[locale] = I18nContainer;

    return I18nContainer;
  };
  const getTempIntlContainers = () => {
    return tempIntlContainers;
  };

  return {tempIntlContainers, getTempIntlContainers, temporaryI18nContainer, createI18nContainer};
}

const {temporaryI18nContainer, getTempIntlContainers, tempIntlContainers, createI18nContainer} = IntlManagerStart();
export {IntlManagerStart, temporaryI18nContainer, getTempIntlContainers, tempIntlContainers, createI18nContainer, getDefaultLocale, getDefaultLangCode};
