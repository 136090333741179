import isEmpty from 'lodash/isEmpty';
import {generateSearchPath} from './boats';

export const locationIncompleteUrlRegion = (currentParams, rewrittenURL, listingLocation) => {
  let urlParams = {};
  if (!currentParams.country && currentParams.region) {
    return generateSearchPath(undefined, undefined, true);
  } else if (currentParams.country && currentParams.region){
    return rewrittenURL;
  }
  if (!currentParams.country || !currentParams.subdivision) {
    urlParams.country = listingLocation.country;
    urlParams.subdivision = listingLocation.subdivision;
    if (currentParams.city) {
      const cityParamArray = currentParams.city.split('-');
      cityParamArray.shift();
      urlParams.city = [cityParamArray.join('-')];
    }
  }
  if (!isEmpty(urlParams)) {
    rewrittenURL = generateSearchPath(urlParams);
  }
  return rewrittenURL;
};

export const locationIncompleteUrlSubdivision = (currentParams, rewrittenURL, listingLocation) => {
  let urlParams = {};
  if (!currentParams.country || !currentParams.subdivision) {
    urlParams.country = listingLocation.country;
    urlParams.subdivision = listingLocation.subdivision;
    if (currentParams.city) {
      const cityParamArray = currentParams.city.split('-');
      cityParamArray.shift();
      urlParams.city = [cityParamArray.join('-')];
    }
    rewrittenURL = generateSearchPath(urlParams);
  }
  return rewrittenURL;
};

export const locationIncompleteUrlCity = (currentParams, listingLocation) => {
  let urlParams = {};
  urlParams.country = listingLocation.country;
  const cityParamArray = currentParams.city.split('-');
  cityParamArray.shift();
  urlParams.city = [cityParamArray.join('-')];
  return generateSearchPath(urlParams);
};

export const dealWithLocalhost = url => {
  if (url.match(/localhost/)) {
    const regex = /(https?:\/\/)[^$]*\$localhost:(\d+)/;
    let newUrl = url.replace(regex, '$1localhost:$2').replace('https', 'http');
    // istanbul ignore next
    if (typeof window !== 'undefined' && window.location?.href) {
      const theURL = new URL(window.location.href);
      const host = `${theURL.protocol}//${theURL.hostname}:${theURL.port}/`;
      newUrl = newUrl.replace(/.*?\$/, host);
    }
    return newUrl;
  }
  return url;
};

// TODO: Create test for this
// istanbul ignore next
export const toValidUrl = (host) => {
  if (typeof host !== 'string' || !host) {
    return null;
  }
  const regex = /^https?:\/\/.+/;
  if (!regex.test(host)) {
    const protocol = host.match(/localhost/) ? 'http' : 'https';
    host = `${protocol}://${host}`;
  }
  const url = new URL(host);
  return url;
};
