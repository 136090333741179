import React, { useContext, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import * as actions from './store/actions';
import ErrorBoundary from './components/ErrorBoundary';
import Routes from './routes';
import { PortalConfigContext } from './config/portal';
import get from 'lodash/get';
import './Defaults.css';
import './App.css';
import '@dmm/lib-react-ui-components/dist/platform.css';


const App = (props) => {
  const context = useContext(PortalConfigContext);
  const portal = get(context, 'name');

  useEffect(() => {
    const loadTheme = async () => {
      await import(`@dmm/lib-react-ui-components/dist/${portal}.css`);
    };
    loadTheme();
     }, [portal]);

  return (
    <ErrorBoundary>
      <Routes {...props} />
    </ErrorBoundary>
  );
};

export default withRouter(connect(
  state => state,
  dispatch => bindActionCreators({
    ...actions,
  }, dispatch),
)(App));
