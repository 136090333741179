import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';
import { Provider as ReduxProvider } from 'react-redux';
import { Frontload } from 'react-frontload';
import { Router } from 'react-router';
import { CookiesProvider } from 'react-cookie';
import { CustomIntlProvider } from './utils/language';

import App from './App';
import createStore from './store/config';
import { PortalConfigProvider } from './config/portal';
import { ABTestProvider } from './context/ABTestContext';
import { ServicesProvider } from './context/ServicesProvider';
import {TPPServiceContainer} from './tppServices/tppDi';
import {getTPPContainerKey} from './tppServices/crossEnvHelpers';

export default createStore().then(({ store, history, host, abTestContext, tpp }) => {
  // uncomment to hardcode abTestContext on development
  // abTestContext = {
  //   'featureFlags': [{ 'id': 2437, 'key': 'bdp-refresh-phase3' }]
  // };
  const languageService = tpp.languageService();
  const Application = (
    <ServicesProvider tpp={tpp}>
      <PortalConfigProvider>
        <CookiesProvider>
          <ABTestProvider value={abTestContext}>
            <ReduxProvider store={store}>
              <CustomIntlProvider location={history} host={host} languageService={languageService}>
                <Router history={history}>
                  <Frontload noServerRender={true}>
                    <App />
                  </Frontload>
                </Router>
              </CustomIntlProvider>
            </ReduxProvider>
          </ABTestProvider>
        </CookiesProvider>
      </PortalConfigProvider>
    </ServicesProvider>
  );

  const root = document.querySelector('#root');
  /* istanbul ignore if */
  if (root.hasChildNodes()) {
    loadableReady(() => {
      const tpp = TPPServiceContainer(getTPPContainerKey());
      const started = tpp.started();
      if (!started) {
        throw new Error('Failed to start TPP');
      }
      ReactDOM.hydrate(Application, root);
    });
  } else {
    ReactDOM.render(Application, root);
  }
});
