import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';
import size from 'lodash/size';
import clone from 'lodash/clone';
import {
  BLOG_ARTICLE_TYPE,
  BLOG_RELATED_ARTICLES_MAX,
  ALLOWED_PARAMS_FOR_OEMDETAILS
} from '../constants/index';
import * as types from './constants';
import * as httpClient from '../utils/httpClient';
import { parseBlogParams } from '../utils/urlHelpers/blog';
import { parseSearchParams as parseEnginesSearchParams } from '../utils/urlHelpers/engines';
import { parseBoatDetailParams } from '../utils/urlHelpers/boat';
import { getConfig } from '../config/portal';
import {
  getConfigParamForLanguage,
  getCurrentLocale,
  getCurrentCurrency
} from '../utils/language';
import { isValidFilters } from '../utils';
import { formatMultiFacetParam } from '../utils/multiFacetHelper';
import { parseEngineDetailParams } from '../utils/urlHelpers/engine';
import {
  getDestinationPath,
  getMappedValues,
  mapRedirectResultsToValues
} from '../utils/urlHelpers/redirects';
import { buildRelatedListingParamsFromListing } from '../utils/api/relatedListings';
import { matchPath } from 'react-router';
import {
  showRelatedBoatArticles,
  showVideos,
  showWordsmithContent
} from './utils';
import { shouldForceCities } from '../utils/locationHelper';
import { getBoatUrl } from '../utils/urlHelpers/boat';
import {
  isFeatureFlagActive,
  FEATURE_FLAG_KEYS
} from '../context/ABTestContext';
import {getRouteConstantsFromI18n} from '../tppServices/translations/constants';
import { isBoatDetailsPageRedesign } from '../utils/newBDPHelper';

import {parseSearchParams} from '../utils/urlHelpers/boats';

const getDataFailure = (err, statusCode = 500) => ({
  type: types.GET_DATA_FAILURE,
  success: false,
  errors: true,
  statusCode: statusCode,
  message: err,
  isWorking: false,
  data: []
});

const postDataFailure = (err, statusCode) => ({
  type: types.POST_DATA_FAILURE,
  success: false,
  errors: true,
  statusCode: statusCode,
  message: err,
  isWorking: false
});

const getDataSuccess = (data, statusCode) => {
  return {
    type: types.GET_DATA_SUCCESS,
    success: true,
    errors: false,
    statusCode: statusCode,
    message: 'got response of things',
    isWorking: false,
    data: data
  };
};

const postDataSuccess = (statusCode) => {
  return {
    type: types.POST_DATA_SUCCESS,
    success: true,
    errors: false,
    statusCode: statusCode,
    message: 'got response of things',
    isWorking: false
  };
};

const getFacetSuccess = (data) => {
  return {
    type: types.GET_FACET_SUCCESS,
    data: data
  };
};

const getDataRequest = (params) => ({
  type: types.GET_DATA_REQUEST,
  params: params
});

const postDataRequest = () => ({
  type: types.POST_DATA_REQUEST
});

const getFacetRequest = (params) => ({
  type: types.GET_FACET_REQUEST,
  params: params
});

const _shouldGetData = () => {
  let lastLocation;
  return (location) => {
    //assume server side render
    if (!lastLocation) {
      lastLocation = location;
      return false;
    }
    let getData = !isEqual(location, lastLocation);
    lastLocation = location;
    return getData;
  };
};

export const shouldGetData = _shouldGetData();

const getSortParam = (value) => ({
  type: types.GET_SORT_PARAM,
  sortBy: value
});

export const getSortByParam = (value) => (dispatch) => {
  return dispatch(getSortParam(value));
};

const shouldAddOEMDetails = (queryParams) => {
  const multiFacetedMakeModel = formatMultiFacetParam(
    queryParams.multiFacetedMakeModel
  );
  return (
    isValidFilters(queryParams, ALLOWED_PARAMS_FOR_OEMDETAILS) &&
    get(multiFacetedMakeModel, 'length') === 1 &&
    get(multiFacetedMakeModel, '0.1.length') === 1
  );
};

export const getData =
  (
    url,
    cookies,
    otherParams,
    abContext,
    parseParamFunction = parseSearchParams
  ) =>
    async (dispatch, getState, http) => {
      const config = getConfig();
      let urlParams = parseParamFunction(url, otherParams?.ownerDetails);

      const seoUrlParams = omit(clone(urlParams), [
        'distance',
        'page',
        'pageSize',
        'sort'
      ]);

      if (urlParams.multiFacetedMakeModel) {
        urlParams.exactMakeMatch = true;
        urlParams.exactModelMatch = true;
      }

      let seoParams = {};
      if (get(config, 'supports.extendedSeoContent', false)) {
        seoParams = {
          wordsmithContentType: showWordsmithContent(seoUrlParams),
          relatedBoatArticles: showRelatedBoatArticles(seoUrlParams),
          videoType: showVideos(seoUrlParams)
        };
      }

      let enablePremiumPlacement = get(config, 'supports.enablePremiumPlacement');
      let enableSponsoredSearch = get(
        config,
        'supports.enableSponsoredSearch',
        true
      );

      const supportsRotationalProductsConfigFlag = get(
        config,
        'supports.rotationalProducts',
        false
      );
      if (supportsRotationalProductsConfigFlag) {
        enablePremiumPlacement = getConfigParamForLanguage(
          'overrideEnablePremiumPlacement',
          enablePremiumPlacement
        );
      }

      const featureFlagEngineDetailsFilter = isFeatureFlagActive(
        FEATURE_FLAG_KEYS.ENGINE_DETAILS_FILTER,
        abContext?.featureFlags || [],
        cookies
      );

      const featureFlagEngineMakesFilter = isFeatureFlagActive(
        FEATURE_FLAG_KEYS.ENGINE_MAKES_FILTER,
        abContext?.featureFlags || [],
        cookies
      );

      const isDynamicContentSupported = get(
        config,
        'supports.dynamicContent',
        true
      );

      const isEngineFilterEnabled = get(config, 'supports.isEngineFilterEnabled', false) && featureFlagEngineDetailsFilter;
      const isEngineMakesFilterEnabled = get(config, 'supports.isEngineMakesFilterEnabled', false) && featureFlagEngineMakesFilter && isEngineFilterEnabled;

      const pageSize = get(config, 'pages.searchResults.pagination.pageSize', 28);

      const params = {
        pageSize: pageSize,
        page: 1,
        facets: [
          'countrySubdivision',
          'make',
          'condition',
          'makeModel',
          'type',
          'class',
          'country',
          'countryRegion',
          'countryCity',
          'fuelType',
          'hullMaterial',
          'hullShape',
          'minYear',
          'maxYear',
          'minMaxPercentilPrices',
          'enginesConfiguration',
          'enginesDriveType',
          'numberOfEngines',
        ].join(','),
        fields: [
          'id',
          'make',
          'model',
          'year',
          'featureType',
          'specifications.dimensions.lengths.nominal',
          'location.address',
          'aliases',
          'owner.logos',
          'owner.name',
          'owner.rootName',
          'owner.location.address.city',
          'owner.location.address.country',
          'price.hidden',
          'price.type.amount',
          'portalLink',
          'class',
          'media',
          'isOemModel',
          'isCurrentModel',
          'attributes',
          'previousPrice',
          'mediaCount',
          'cpybLogo'
        ].join(','),
        useMultiFacetedFacets: true,
        enableSponsoredSearch,
        enablePremiumPlacement: enablePremiumPlacement,
        enableOEM: config.supports.enableOEM,
        locale: getCurrentLocale(true),
        ...otherParams,
        ...urlParams,
        ...seoParams
      };

      if (isDynamicContentSupported) {
        params.facets += ',minTotalHorsepowerPercentil,maxTotalHorsepowerPercentil,minLengthPercentil,maxLengthPercentil';
      }

      if (isEngineMakesFilterEnabled) {
        params.facets += ',normalizedEngineMake';
      }

      // A/B Testing - YW SRP 3 COLUMN requires 26 results
      if (
        isFeatureFlagActive(
          FEATURE_FLAG_KEYS.THREE_COLUMN,
          abContext?.featureFlags || [],
          cookies
        )
      ) {
        params.pageSize = 26;
      }

      if (urlParams.city && urlParams.city.split(',').length === 1) {
        params.facets += ',cityPostalCode';
      }

      if (shouldAddOEMDetails(params)) {
        params.OEMDetails = true;
      }

      const cleanedUrlParams = omit(urlParams, [
        'distance',
        'page',
        'pageSize',
        'sort'
      ]);
      if (
        size(cleanedUrlParams) === 1 &&
        (urlParams.multiFacetedMakeModel || urlParams.multiFacetedBoatTypeClass)
      ) {
        params.facets +=
          ',minMaxPercentilPrices,minYear,maxYear,hullShape,enginesConfiguration,enginesDriveType,fuelType';
        if (isEngineMakesFilterEnabled) {
          params.facets += ',normalizedEngineMake';
        }
        if (isDynamicContentSupported) {
          params.facets += ',activities,minTotalHorsepowerPercentil,maxTotalHorsepowerPercentil,minLengthPercentil,maxLengthPercentil';
        }
      }

      if (isDynamicContentSupported && size(cleanedUrlParams) === 1 && urlParams.multiFacetedBoatTypeClass) {
        params.facets += ',avgTotalHorsepowerPercentil';
      }

      const customUom = get(cookies, 'uom', false);
      if (customUom) {
        params.uom = customUom;
      }

      if (
        isFeatureFlagActive(
          FEATURE_FLAG_KEYS.SORT,
          abContext?.featureFlags || [],
          cookies
        )
      ) {
        params.variant = '1';
      }

      if (getConfigParamForLanguage('supports.advantageSort', false)) {
        params.advantageSort = '1';
      }

      if (shouldForceCities(params.country)) {
        params.forceCities = true;
      }

      const enableSponsoredSearchExactMatch = get(
        config,
        'supports.enableSponsoredSearchExactMatch',
        false
      );

      if (enableSponsoredSearchExactMatch) {
        params.enableSponsoredSearchExactMatch = true;
      }

      const enableFuzzySponsoredSearch = get(
        config,
        'supports.enableFuzzySponsoredSearch',
        false
      );

      if (enableFuzzySponsoredSearch &&
        isFeatureFlagActive(
          FEATURE_FLAG_KEYS.FUZZY_SPONSORED_BOATS,
          abContext?.featureFlags || [],
          cookies
        )
      ) {
        params.enableFuzzySponsoredSearch = true;
        params.pageSize = params.pageSize - 3;
      }

      const enableRandomizedSponsoredBoatsSearch = get(
        config,
        'supports.enableRandomizedSponsoredBoatsSearch',
        false
      );

      if (enableRandomizedSponsoredBoatsSearch) {
        params.randomizedSponsoredBoatsSearch = true;
      }

      const enableSponsoredListingsCarousel = get(
        config,
        'supports.enableSponsoredListingsCarousel',
        false
      );

      if (enableSponsoredListingsCarousel) {
        const randomSponsoredBoatsSize = get(
          config,
          'supports.randomSponsoredBoatsSize',
          8
        );
        params.randomSponsoredBoatsSize = randomSponsoredBoatsSize;
      }

      dispatch(getDataRequest(params));

      const apiClient = http || httpClient.getHttpClient();
      const requestData = http
        ? { params, headers: { 'x-type-portal': types.HEADER_PORTAL_TYPE } }
        : { params };

      try {
        const response = await apiClient.get('/search/boat', requestData);
        const data = response.data;

        /* istanbul ignore next */
        if (!data.facets) {
          data.facets = {};
        }

        return dispatch(getDataSuccess(data));
      } catch (err) {
        const errorMessage = get(err, 'response.data', '').toString();
        const statusCode = err?.response?.status || err?.statusCode || 500;
        dispatch(getDataFailure(errorMessage, statusCode));
      }
    };

export const getEnginesData =
  (url, cookies, otherParams) => async (dispatch, getState, http) => {
    let urlParams;
    if (otherParams && otherParams.ownerDetails) {
      urlParams = parseEnginesSearchParams(url, otherParams.ownerDetails);
    } else {
      urlParams = parseEnginesSearchParams(url);
    }

    if (urlParams.category) {
      urlParams.category =
        urlParams.category === 'outboard'
          ? 'outboard,outboard-4s,outboard-2s'
          : urlParams.category;
    }

    let params = {
      page: 1,
      pageSize: 28,
      enableSponsoredSearch: true,
      locale: getCurrentLocale(true),
      ...otherParams,
      ...urlParams
    };

    dispatch(getDataRequest(params));
    const apiClient = http || httpClient.getHttpClient();

    try {
      const response = await apiClient.get('/search/engine', { params });
      const data = response.data;
      data.facets.makeModel = data.facets.makeModel.filter(make => make.value && make.value.length > 1 && !/^[^a-zA-Z0-9]+$/.test(make.value));
      return dispatch(getDataSuccess(data));
    } catch (err) {
      const errorMessage = get(err, 'response.data', '').toString();
      const statusCode = err?.response?.status || err?.statusCode || 500;
      dispatch(getDataFailure(errorMessage, statusCode));
    }
  };

export const getNextPreviousEnginesData = (urlParams) => {
  const currency = get(getConfig().currency, 'abbr');

  if (urlParams.category) {
    urlParams.category =
      urlParams.category === 'outboard'
        ? 'outboard,outboard-4s,outboard-2s'
        : urlParams.category;
  }

  const search = '/search/engine';
  const params = {
    pageSize: 28,
    page: 1,
    currency: currency,
    ...urlParams
  };
  return httpClient.getHttpClient().get(search, { params });
};

export const getNextPreviousData = (urlParams) => {
  const search = '/search/boat';
  const params = {
    pageSize: get(getConfig(), 'pages.details.search.pagination.pageSize', 28),
    page: 1,
    facets: ['fuelType'].join(','),
    fields: ['id', 'make', 'model', 'year', 'portalLink'].join(','),
    useMultiFacetedFacets: true,
    ...urlParams
  };
  return httpClient.getHttpClient().get(search, { params });
};

export const getFacets = (url, otherParams, featureFlagEngineMakesFilter) => async (dispatch) => {
  const isEngineMakesFilterEnabled = get(getConfig(), 'supports.isEngineMakesFilterEnabled', false) && featureFlagEngineMakesFilter;
  let search = '/search/boat';
  let urlParams = parseSearchParams(url);
  let params = {
    ...otherParams,
    ...urlParams,
    useMultiFacetedFacets: true,
    pageSize: 0,
    page: 1,
    facets:
      'countrySubdivision,make,makeModel,class,country,countryRegion,countryCity,fuelType,hullMaterial,enginesDriveType'
  };
  if (isEngineMakesFilterEnabled) {
    params.facets += ',normalizedEngineMake';
  }

  if (urlParams.city && urlParams.city.split(',').length === 1) {
    params.facets += ',cityPostalCode';
  }

  dispatch(getFacetRequest(params));

  return httpClient
    .getHttpClient()
    .get(search, { params })
    .then((res) => dispatch(getFacetSuccess(res.data)))
    .catch((err) => {
      const errorMessage = get(err, 'response.data', '').toString();
      dispatch(getDataFailure(errorMessage, get(err, 'response.status', 500)));
    });
};

export const getEnginesFacets = (url, otherParams) => async (dispatch) => {
  const search = '/search/engine';
  const currency = get(getConfig().currency, 'abbr');
  let urlParams;

  if (otherParams && otherParams.ownerDetails) {
    urlParams = parseEnginesSearchParams(url, otherParams.ownerDetails);
  } else {
    urlParams = parseEnginesSearchParams(url);
  }

  if (urlParams.category) {
    urlParams.category =
      urlParams.category === 'outboard'
        ? 'outboard,outboard-4s,outboard-2s'
        : urlParams.category;
  }

  let params = {
    pageSize: 28,
    page: 1,
    currency: currency,
    enableSponsoredSearch: true,
    ...otherParams,
    ...urlParams
  };

  dispatch(getFacetRequest(params));

  return httpClient
    .getHttpClient()
    .get(search, { params })
    .then((res) => dispatch(getFacetSuccess(res.data)))
    .catch((err) => {
      dispatch(getDataFailure(err.toString()));
    });
};

const addHrefToBoatsList = (list) => {
  return list.map((l) => ({ ...l, href: getBoatUrl(l) }));
};

export const getBoatData = (url) => async (dispatch, getState, http) => {

  const existingBoatData = get(getState(), 'app.data');
  if (!isEmpty(existingBoatData)) {
    return existingBoatData;
  }

  let boatParams = parseBoatDetailParams(url);
  const locale = getCurrentLocale(true);
  const config = getConfig();
  const oemRelatedListings = get(
    getConfig().supports,
    'oemRelatedListings',
    null
  );
  const isActiveBDPRedesign = isBoatDetailsPageRedesign(config);
  const similarBoats = get(config, 'supports.similarBoats', false);
  const relatedArticles = get(config, 'supports.relatedArticles', false);
  let boatListing = `/boat/${boatParams.id
    }?otherDealerBoats=true&locale=${locale}${oemRelatedListings ? '&getRelatedListings=true' : ''
    }${similarBoats ? '&similarBoats=true' : ''}${relatedArticles ? '&getRelatedArticles=true' : ''
    }${isActiveBDPRedesign ? '&moreFromThisDealerBoatCount=8' : ''}`;

  let apiClient = http || httpClient.getHttpClient();
  dispatch(getDataRequest(boatParams));
  const data = apiClient
    .get(boatListing)
    .then((res) => {
      // Add the href attribute to every boat list inside the listing.
      let listing = res.data;
      // Other boats from dealer.
      if (Array.isArray(listing.dealerListings)) {
        listing.dealerListings = addHrefToBoatsList(listing.dealerListings);
      }
      // Similar boats.
      if (
        Array.isArray(get(listing, 'similarBoats.records.records', undefined))
      ) {
        listing.similarBoats.records.records = addHrefToBoatsList(
          listing.similarBoats.records.records
        );
      }
      dispatch(getDataSuccess(listing));
    })
    .catch((err) => {
      let statusCode = get(
        err,
        'statusCode',
        get(err, 'response.status', get(err, 'status', 500))
      );
      // NOTE: If the boat is not active by any reason, api-node-platform returns an enum status value (not numeric), but
      //       removes the html response, so if the status is not numeric we should redirecto to the SRP.
      statusCode = isNaN(statusCode) ? 301 : statusCode;
      const errorMessage = get(err, 'message', null);
      const data = get(err, 'data', err);
      if (statusCode === 301 && data) {
        dispatch(getDataSuccess(data, statusCode));
      } else {
        dispatch(
          getDataFailure(
            errorMessage ? errorMessage : err.toString(),
            statusCode
          )
        );
      }
    });
  return data;
};

export const getEngineData = (url) => async (dispatch, getState, http) => {
  let boatParams = parseEngineDetailParams(url);
  const locale = getCurrentLocale(true);
  let engineListing = `/engine/${boatParams.id}?otherDealerEngines=true&locale=${locale}`;
  let apiClient = http || httpClient.getHttpClient();
  dispatch(getDataRequest(boatParams));
  const data = apiClient
    .get(engineListing)
    .then((res) => dispatch(getDataSuccess(res.data)))
    .catch((err) => {
      let statusCode = get(
        err,
        'statusCode',
        get(err, 'response.status', get(err, 'status', 500))
      );
      // NOTE: If the engine is not active by any reason, api-node-platform returns an enum status value (not numeric), but
      //       removes the html response, so if the status is not numeric we should redirecto to the SRP.
      statusCode = isNaN(statusCode) ? 301 : statusCode;
      const data = get(err, 'data', err);
      if (statusCode === 301 && data) {
        dispatch(getDataSuccess(data, statusCode));
      } else {
        const errorMessage = get(err, 'response.data', '').toString();
        dispatch(getDataFailure(errorMessage, statusCode));
      }
    });
  return data;
};

export const getEditorialContent =
  (url) => async (dispatch, getState, http) => {
    // Need to remove the language folder as it will get to the editorial API and will mess the URL for the desired article.
    const permalink = `${url}/`.replace(/^\/[a-z]{2}\//, '/'); // Add trailing slash to URL so it matches the exact permalink value
    const queryParams = `permalink=${encodeURIComponent(permalink)}`;
    const locale = `locale=${getCurrentLocale(true)}`;
    const editorialLink = `/editorial/articles?${queryParams}&${locale}`;

    let apiClient = http || httpClient.getHttpClient();
    dispatch(getDataRequest(editorialLink));
    const data = apiClient
      .get(editorialLink)
      .then((res) => {
        dispatch(getDataSuccess(res.data));
      })
      .catch((err) => {
        const statusCode = get(
          err,
          'statusCode',
          get(err, 'response.status', get(err, 'status', 500))
        );
        const errorMessage = get(err, 'response.data', '').toString();
        dispatch(getDataFailure(errorMessage, statusCode));
      });
    return data;
  };

export const getRedirectData =
  (url, search = '', subdomain = '') =>
    async (dispatch, getState, http) => {
      let apiClient = http || httpClient.getHttpClient();

      const queryParams = [];
      if (subdomain) {
        queryParams.push(`subdomain=${subdomain}`);
      }
      const transformedSearch = search.replace(/%20/g, "+");

      const legacy =
        '/legacy/redirect/' +
        encodeURIComponent(`${url}${transformedSearch}`) +
        (queryParams.length ? `?${queryParams.join('&')}` : '');

      dispatch(getDataRequest(legacy));
      try {
        const res = await apiClient.get(legacy);
        dispatch(
          getDataSuccess({
            redirectTo: get(res.data, 'destination', undefined)
          })
        );
      } catch (error) {
        const statusCode = get(
          error,
          'statusCode',
          get(error, 'response.status', 500)
        );
        const message = error.toString();
        dispatch(getDataFailure(message, statusCode));
      }
    };

export const getRedirectAndEditorialContent =
  (url, onNotFoundError) => async (dispatch, getState, http) => {
    // Need to remove the language folder as it will get to the editorial API and will mess the URL for the desired article.
    const permalink = `${url}/`.replace(/^\/[a-z]{2}\//, '/'); // Add trailing slash to URL so it matches the exact permalink value
    const queryParams = `permalink=${encodeURIComponent(permalink)}`;
    const locale = `locale=${getCurrentLocale(true)}`;
    const editorialLink = `/editorial/articles?${queryParams}&${locale}`;

    let apiClient = http || httpClient.getHttpClient();
    dispatch(getDataRequest(editorialLink));

    try {
      const res = await apiClient.get(editorialLink);
      dispatch(getDataSuccess(res.data));
    } catch (fetchError) {
      const statusCode = get(
        fetchError,
        'statusCode',
        get(fetchError, 'response.status', get(fetchError, 'status', 500))
      );

      if (statusCode !== 404) {
        dispatch(getDataFailure(fetchError.toString(), statusCode));
      } else {
        await onNotFoundError(dispatch, getState, http);
      }
    }
  };

export const getBlogContent = (url) => async (dispatch, getState, http) => {
  const blogConfig = get(getConfig().pages, 'blog', {});
  const pageSize = get(blogConfig.pagination, 'pageSize', 9);
  const urlParams = parseBlogParams(url);
  const params = {
    maxRelatedArticles: BLOG_RELATED_ARTICLES_MAX,
    type: BLOG_ARTICLE_TYPE,
    locale: getCurrentLocale(true),
    pageSize: pageSize,
    page: 1,
    seoItems: 'seo-meta-tags',
    ...urlParams
  };
  const blog = '/editorial/articles';

  let apiClient = http || httpClient.getHttpClient();
  dispatch(getDataRequest(params));
  const data = apiClient
    .get(blog, { params })
    .then((res) => {
      dispatch(getDataSuccess(res.data));
    })
    .catch((err) => {
      const statusCode = get(err, 'response.status', 500);
      const errorMessage = get(err, 'response.data', '').toString();
          dispatch(getDataFailure(errorMessage,  statusCode));
    });
  return data;
};

export const carouselButtonClick = (carouselClickCount) => {
  return {
    type: types.INCREMENT_CAROUSEL_CLICKCOUNT,
    carouselClickCount: carouselClickCount
  };
};

export const setIsWorking = (value) => ({
  type: types.SET_IS_WORKING,
  data: value
});

export const setPartyRecords = (data) => ({
  type: types.SET_PARTY_RESULTS,
  data
});

export const setPartyResultsError = () => ({
  type: types.SET_PARTY_RESULTS_ERROR
});

export const setPartySearchParams = (params) => ({
  type: types.SET_PARTY_SEARCH_PARAMS,
  data: params
});

export const setPartySearchLocationSuggestions = (data) => ({
  type: types.SET_PARTY_SEARCH_AUTO_SUGGESTIONS,
  data
});

export const setPartySearchAutocompleteText = (data) => ({
  type: types.SET_PARTY_SEARCH_AUTO_TEXT,
  data
});

const setAutocompleteText = () => async (dispatch, getState, http) => {
  const params = get(getState(), 'app.parties.search.params', {});

  if (!params.location) {
    return;
  }

  const url = `/location/${params.location}`;
  const apiClient = http || httpClient.getHttpClient();

  try {
    const response = await apiClient.get(url);
    if (response.data) {
      const text = ['locality', 'region2', 'region1', 'iso', 'postcode']
        .map((key) => response.data[key])
        .filter((value) => value && value !== '-')
        .join(', ');
      dispatch(setPartySearchAutocompleteText(text));
    }
  } catch (e) {
    dispatch(setPartySearchAutocompleteText(params.location));
  }
};

export const getPartyResults =
  (params = {}, options = {}) =>
    async (dispatch, getState, http) => {
      const isLoading = get(getState(), 'app.isWorking');

      if (isLoading) {
        return;
      }

      dispatch(setIsWorking(true));

      params.locale = getCurrentLocale(true);

      const apiParams = omitBy(params, (value) => !value);

      dispatch(setPartySearchParams(apiParams));

      const apiClient = http || httpClient.getHttpClient();
      const url = '/search/party';

      try {
        const response = await apiClient.get(url, {
          params: apiParams
        });

        dispatch(setPartyRecords(get(response, 'data', {})));
      } catch {
        dispatch(setPartyResultsError());
      }

      dispatch(setIsWorking(false));

      if (options.updateLocationText) {
        await dispatch(setAutocompleteText());
      }
    };

let autoCompleteCalls = 0;

export const getLocationSuggestions =
  (text) => async (dispatch, getState, http) => {
    const callId = ++autoCompleteCalls;
    const apiClient = http || httpClient.getHttpClient();
    const url = '/location/suggestion';
    const params = { keyword: text };
    try {
      const response = await apiClient.get(url, { params });
      if (callId >= autoCompleteCalls) {
        // only takes the latest call to the server
        const data = get(response, 'data', []);
        dispatch(setPartySearchLocationSuggestions(data));
      }
    } catch {
      // do nothing since this is just for autocompletion
    }
  };

export const setConfigGeoRegion = (data) => ({
  type: types.SET_CONFIG_GEO_REGION,
  data
});

export const getConfigGeoRegion = () => async (dispatch, getState, http) => {
  const region = get(getState(), 'app.config.geo.region');

  if (!isEmpty(region)) {
    return;
  }

  const apiClient = http || httpClient.getHttpClient();
  const url = '/config/geo/region';
  try {
    const response = await apiClient.get(url, {});
    dispatch(setConfigGeoRegion(get(response, 'data', {})));
  } catch (error) {
    const errorMessage = get(error, 'response.data', '').toString();
          dispatch(getDataFailure(errorMessage, get(error, 'statusCode', 500)));
  }
};

export const getHomeData =
  (otherParams = {}) =>
    async (dispatch) => {
      const search = '/home';
      const language = getCurrentLocale();
      const cfg = getConfig();
      const cfgLangs = cfg.languages[language];
      const enableHomeBlogSection = getConfigParamForLanguage(
        'supportsBlogHome',
        false
      );
      const adType = cfg?.pages?.home?.adType;
      const enablePrivateFeatured = cfg?.supports?.enablePrivateFeatured;
      const selectedLocale = cfgLangs.apiLocale || getCurrentLocale(true);
      const currency = getCurrentCurrency();
      const params = {
        selectedLocale,
        enableHomeBlogSection,
        currency: currency?.currency?.code,
        ...otherParams
      };

      const supportsRotationalProductsConfigFlag = get(
        cfg,
        'supports.rotationalProducts',
        false
      );
      // Send new adType value when rotational flag is enabled
      params.adType = supportsRotationalProductsConfigFlag
        ? 'featured_boat_rotational'
        : adType;

      if (enablePrivateFeatured) {
        params.enablePrivateFeatured = enablePrivateFeatured;
      }

      let apiClient = httpClient.getHttpClient();

      dispatch(getDataRequest(params));
      const data = apiClient
        .get(search, { params })
        .then((res) => {
          dispatch(getDataSuccess(res?.data));
        })
        .catch((err) => {
          const errorMessage = get(err, 'response.data', '').toString();
          dispatch(getDataFailure(errorMessage, get(err, 'response.status', 500)));
        });

      return data;
    };

export const getRedirectPathName =
  (location, match) => async (dispatch, getState, http, i18nService) => {
    const pathname = location.pathname;
    const search = location.search;
    const facetsMap = get(getConfig().redirects, 'facetsMap', {});
    const redirectsMaps = get(getConfig().redirects, 'redirectsMaps', []);
    const matchedParams = match.params;
    const mappedValues = getMappedValues(matchedParams, facetsMap);
    const redirectMap = redirectsMaps.find((redirectMap) =>
      matchPath(pathname, { path: redirectMap.origins, exact: true })
    );
    if (
      redirectMap &&
      (redirectMap.bdp || redirectMap.dealer || redirectMap.edp)
    ) {
      const legacyId = mappedValues.id;
      let pageType = 'bdp';
      if (redirectMap.dealer) {
        pageType = 'dealer';
      } else if (redirectMap.edp) {
        pageType = 'edp';
      }
      let legacy = `/legacy/${pageType}/${legacyId}`;
      if (
        redirectMap.lookupType &&
        (redirectMap.alias || redirectMap.connectionValue)
      ) {
        legacy += `?lookupType=${redirectMap.lookupType}`;
        if (redirectMap.alias) {
          legacy += `&alias=${redirectMap.alias}`;
        }
        if (redirectMap.connectionValue) {
          legacy += `&connectionValue=${redirectMap.connectionValue}`;
        }
      }
      let apiClient = http || httpClient.getHttpClient();
      dispatch(getDataRequest());
      const data = apiClient
        .get(legacy)
        .then((res) => {
          if (!redirectMap.dealer) {
            mapRedirectResultsToValues(mappedValues, res.data);
          }
          return getDestinationPath(
            redirectsMaps,
            pathname,
            search,
            mappedValues,
            res.data
          );
        })
        .then((redirectTo) => {
          dispatch(getDataSuccess({ redirectTo }));
        })
        .catch(() => {
          const routeConstants = getRouteConstantsFromI18n(i18nService);
          const destPathname = redirectMap.dealer
            ? `${routeConstants.PARTY_SEARCH_URL_ROOT}`
            : redirectMap.edp
              ? `${routeConstants.ENGINES_SEARCH_URL_ROOT}`
              : `${routeConstants.SEARCH_URL_ROOT}`;
          dispatch(getDataSuccess({ redirectTo: { destPathname } }));
        });
      return data;
    }
    // If is a normal redirect...
    const redirectTo = await getDestinationPath(
      redirectsMaps,
      pathname,
      search,
      mappedValues
    );
    dispatch(getDataSuccess({ redirectTo }));
    return;
  };

export const createSubscription =
  (email) => async (dispatch, getState, http) => {
    const subscribe = '/marketing/subscriber';
    const body = {
      email
    };
    let apiClient = http || httpClient.getHttpClient();
    dispatch(postDataRequest());
    const data = apiClient
      .post(subscribe, body)
      .then((res) => {
        dispatch(postDataSuccess(res.status));
      })
      .catch((err) => {
        const errorMessage = get(err, 'response.data', '').toString();
          dispatch(postDataFailure(errorMessage, get(err, 'response.status', 500)));
      });
    return data;
  };

export const createSearchAlert =
  ({ userEmail, searchParams, searchUrl, criteria }, postSuccessCallback) =>
    async (dispatch, getState, http) => {
      const search = '/pbs/save';

      const body = {
        'username': userEmail,
        'searchParams': searchParams,
        'searchUrl': searchUrl,
        'criteria': criteria,
        'locale': getCurrentLocale(true)
      };

      let apiClient = http || httpClient.getHttpClient();
      dispatch(postDataRequest());
      const data = apiClient
        .post(search, body)
        .then((res) => {
          postSuccessCallback();
          dispatch(postDataSuccess(res.status));
        })
        .catch((err) => {
          const errorMessage = get(err, 'response.data', '').toString();
          dispatch(postDataFailure(errorMessage, get(err, 'response.status', 500)));
        });

      return data;
    };

export const getRelatedListings = (params) => {
  const search = '/search/boat/related';
  return httpClient.getHttpClient().get(search, { params });
};

export const showAdditionalLeadsModal =
  (leadData) => async (dispatch, getState) => {
    try {
      const listing = get(getState(), 'app.data');
      let paramList = get(getConfig(), 'pages.details.additionalLeads.params', [
        'location',
        'make',
        'model',
        'class'
      ]);
      let params = buildRelatedListingParamsFromListing(
        getConfig(),
        paramList,
        listing
      );
      let relatedListings = await getRelatedListings(params);
      if (relatedListings.data) {
        while (relatedListings.data.length < 3 && paramList.length > 0) {
          paramList = paramList.pop();
          params = buildRelatedListingParamsFromListing(
            getConfig(),
            paramList,
            listing
          );
          relatedListings = await getRelatedListings(params);
        }
        const data = {
          leadData,
          listings: get(relatedListings, 'data', {})
        };
        dispatch(setAdditionalLeadsData(data));
        dispatch(openAdditionalLeadsModal());
      } else {
        dispatch(setAdditionalLeadsData({ leadData: {}, listings: [] }));
        dispatch(closeAdditionalLeadsModal());
      }
    } catch (e) {
      dispatch(setAdditionalLeadsData({ leadData: {}, listings: [] }));
      dispatch(closeAdditionalLeadsModal());
    }
  };

export const setAdditionalLeadsData = (data) => ({
  type: types.SET_ADDITIONAL_LEADS_DATA,
  data
});

export const openAdditionalLeadsModal = () => ({
  type: types.TOGGLE_ADDITIONAL_LEADS_MODAL,
  data: true
});

export const closeAdditionalLeadsModal = () => ({
  type: types.TOGGLE_ADDITIONAL_LEADS_MODAL,
  data: false
});

let autoCompleteMakeModelCalls = 0;
export const getMakeModelTypeahead =
  (text, extraParams = []) =>
    async (dispatch, getState, http) => {
      const callId = ++autoCompleteMakeModelCalls;
      const url = `/make-model/suggestion?keyword=${text}${extraParams.length ? '&' + extraParams.join('&') : ''
        }`;
      const apiClient = http || httpClient.getHttpClient();
      try {
        const response = await apiClient.get(url);
        if (callId >= autoCompleteMakeModelCalls) {
          // only takes the latest call to the server
          const data = get(response, 'data', []);
          dispatch(setMakeModelSearchSuggestions(data));
        }
      } catch {
        // Do nothing since this is just for autocompletion
      }
    };

export const setMakeModelSearchSuggestions = (data) => ({
  type: types.SET_MAKEMODEL_SEARCH_AUTO_SUGGESTIONS,
  data
});

export const getSbpData =
  (params, imtID, currentSbpListings = []) => async (dispatch, getState, http) => {
    const apiClient = http || httpClient.getHttpClient();
    const endPoint = `/search/boat/related/${imtID}`;

    try {
      dispatch(getDataRequest(params));
      const res = await apiClient.get(endPoint, {
        params: { ...params, pageSize: 27 },
        headers: { 'x-type-portal': types.HEADER_PORTAL_TYPE }
      });
      return dispatch(getDataSuccess({ sbpListings: [...currentSbpListings, ...res.data] }));
    } catch (err) {
      const statusCode = get(err, 'response.status', '');
      const data = get(err, 'response.data');
      if (statusCode === 301 && data) {
        return dispatch(getDataSuccess(data, statusCode));
      }
      return dispatch(getDataFailure(err.toString(), statusCode));
    }
  };
