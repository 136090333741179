import get from 'lodash/get';
import find from 'lodash/find';
import types from './data/types.json';
import classes from './data/classes.json';
import { getConfig } from '../config/portal';
import { getCurrency, getLocale } from './uomHelper';
import { formatPrice } from '@dmm/lib-common/lib/formatting';
import {unslugify} from './urlHelpers/shared';
import { syncTranslationElements } from '../tppServices/translations/intlManager';

export const getType = (typeCode) => {
  let type = find(types, (item) => {
    return item.value === typeCode;
  });
  return type;
};

export const getClass = (classCode) => {
  let category = find(classes, (item) => {
    return item.value === classCode;
  });
  return category;
};

export const isMobileResolution = () => {
  return window.innerWidth < 970;
};

export const isTabletResolution = () => {
  return window.innerWidth >= 768 && window.innerWidth < 970;
};

export const isTabletOrSmallLaptopResolution = () => {
  return window.innerWidth >= 768 && window.innerWidth < 1320;
};

export const isSafelyInitializedWithLayout = (layout = 'mobile') => {
  if (typeof window !== 'undefined') {
    switch (layout) {
      case 'tablet':
        return isTabletResolution();
      case 'mobile':
        return isMobileResolution();
      case 'desktop':
        return !isMobileResolution() && !isTabletResolution();
    }
  }
  // Server side rendering does not have the window object initialized yet, so we assume a safe initialization.
  return true;
};

export const BREAKPOINTS = {
  tablet: 'tablet',
  desktop: 'desktop',
  mobile: 'mobile',
  tabletOrSmallLaptop: 'tabletOrSmallLaptop'
};

export const getBreakpoint = () => {
  const isTablet = isTabletResolution();
  const isMobile = isMobileResolution();
  if (isTablet) {
    return BREAKPOINTS.tablet;
  }
  if (isMobile) {
    return BREAKPOINTS.mobile;
  }
  return BREAKPOINTS.desktop;
};

export const addResizeParams = (url, resize, modified, format = '') => {
  if (resize.width) {
    let timestamp = (modified ? new Date(modified) : new Date()).getTime();
    return (
      _stripQueryParams(url) +
      `?w=${resize.width}&h=${resize.height}&t=${timestamp}&exact${
        format ? '&format=' + format : ''
      }`
    );
  }
  return url;
};

export const getAddress = (address) => {
  let country;
  const { formatMessage: t, messages } = syncTranslationElements();
  try {
    country = t(get(messages, `countries.${address.country}`));
  } catch (e) {
    country = address.country;
  }
  let countrySubDivisionCode;
  try {
    countrySubDivisionCode = t(
      get(
        messages,
        `countrySubdivision.${address.country}.${address.countrySubDivisionCode}`
      )
    );
  } catch (e) {
    countrySubDivisionCode = address.countrySubDivisionCode;
  }
  return [
    address.street,
    address.city,
    countrySubDivisionCode,
    address.postalCode,
    country
  ]
    .filter((addressItem) => !!addressItem)
    .join(', ');
};

// Regex for strings starting with a simple or accentuated letter as per https://stackoverflow.com/a/26900132
export const capitalizeString = (str) =>
  str.replace(/^[A-Za-zÀ-ÖØ-öø-ÿ]/, (c) => c.toUpperCase());

export const capitalizeWords = (words) =>
  words
    .split(/ /g)
    .map((word) => capitalizeString(word))
    .join(' ');

export const toHTMLId = (str) => str.replace(/\s+/g, '-').toLowerCase();

const _stripQueryParams = (url) => {
  return url.split('?')[0];
};

export const getAssetUrl = (path) => {
  return `${get(getConfig(), 'client.cdn.endpoint')}${path}`;
};

export const normalizeString = (string) =>
  string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const translateMake = (make) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const translationList = [{ other: t(messages.otherMake) }];
  const translation = find(translationList, make);
  return translation ? translation[make] : make;
};

export const isManufacturerListing = (isOemModel) => !!isOemModel;

export const showMoreProducts = (moreProducts) => moreProducts === true;

export const getPriceDrop = (
  previousPriceObj,
  actualPriceObj,
  lang,
  formattingOptions
) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  if (!previousPriceObj || !actualPriceObj || actualPriceObj.hidden) {
    return null;
  }
  const previousCurrency = previousPriceObj.currency;
  const actualPrice = get(actualPriceObj, `type.amount.${previousCurrency}`);
  const correctCurrency = get(
    formattingOptions,
    'currency.code',
    getCurrency()
  );
  const currencyConversionRate =
    get(actualPriceObj, `type.amount.${correctCurrency}`) /
    get(actualPriceObj, `type.amount.${previousCurrency}`);
  let priceDrop = {
    amount: parseInt(
      (previousPriceObj.localPrice - actualPrice) * currencyConversionRate
    ),
    currency: correctCurrency
  };
  if (priceDrop.amount <= 0) {
    return null;
  }
  const formattedPrice = formatPrice(
    priceDrop.amount,
    priceDrop.currency,
    getLocale(),
    formattingOptions
  );
  const previousDate = new Date(previousPriceObj.revisedDate);
  return `${t(
    messages.boatDetails.priceDrop
  )} ${formattedPrice} (${formatShortDate(previousDate, lang)})`;
};

export const getFormattedPriceDrop = (
  previousPriceObj,
  actualPriceObj,
  formattingOptions
) => {
  if (!previousPriceObj || !actualPriceObj || actualPriceObj.hidden) {
    return null;
  }
  const previousCurrency = previousPriceObj.currency;
  const actualPrice = get(actualPriceObj, `type.amount.${previousCurrency}`);
  const correctCurrency = get(
    formattingOptions,
    'currency.code',
    getCurrency()
  );
  const currencyConversionRate =
    get(actualPriceObj, `type.amount.${correctCurrency}`) /
    get(actualPriceObj, `type.amount.${previousCurrency}`);
  let priceDrop = {
    amount: parseInt(
      (previousPriceObj.localPrice - actualPrice) * currencyConversionRate
    ),
    currency: correctCurrency
  };
  if (priceDrop.amount <= 0) {
    return null;
  }
  const formattedPrice = formatPrice(
    priceDrop.amount,
    priceDrop.currency,
    getLocale(),
    formattingOptions
  );
  return formattedPrice;
};

export const formatShortDate = (date, lang) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  if (lang === 'us') {
    return `${months[date.getMonth()]} ${date.getDate()}`;
  }
  return `${date.getDate()} ${months[date.getMonth()]}`;
};
export const shouldDecreaseFontBoat = (
  headingHeight,
  twoLinesHeight,
  fontSize,
  minimumFontSize
) => headingHeight > twoLinesHeight && fontSize > minimumFontSize;

export const removeDuplicatedWords = (string) => {
  return string
    .split(' ')
    .filter((value, index, array) => {
      return value !== array[index + 1];
    })
    .join(' ');
};

export const getFacetTranslation = (messagePath) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  const message = get(messages, messagePath);
  return message ? t(message) : '';
};

export const unhyphenateUrlComponents = (string) => {
  string = string.toLowerCase();
  string = string.replace(/(--){2,}/, ' - ');
  string = string.split('--').join('..');
  string = string.split('-').join(' ');
  string = string.split('$').join('&');
  string = unslugify(string, '..', '-');
  return string;
};

export const clearEmptyProps = (ob) => {
  const newOb = { ...ob };
  for (const key in newOb) {
    if (!newOb[key]) {
      delete newOb[key];
    }
  }
  return newOb;
};

export const getRenderedHeight = (htmlString, elementSelector) => {
  const renderedWidth = document.querySelector(elementSelector).offsetWidth;
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;
  tempElement.style.position = 'absolute';
  tempElement.style.left = '-9999px';
  tempElement.style.top = '-9999px';
  tempElement.style.width = `${renderedWidth}px`;
  document.body.appendChild(tempElement);
  const height = tempElement.offsetHeight;
  document.body.removeChild(tempElement);
  return height;
};

