import React, { PureComponent } from 'react';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DataLayer from '../../components/DataLayer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AppBanner from '../../components/AppBanner';
import CookiesDisclaimer from '../../components/CookiesDisclaimer';
import './styles.css';
import SiteMeta from './SiteMeta';
import {PortalConfigContext} from '../../config/portal';
import OptionalScripts from './OptionalScripts';
export default class Default extends PureComponent {
  state = {
    appBannerHeight: 0
  }

  getAppBannerHeight = (appBannerHeight) => {
    this.setState({
      appBannerHeight: appBannerHeight
    });
  }

  render() {
    const {
      children,
    } = this.props;
    const detailClass = { 'with-sticky-contact': this.props.pageType === 'BoatDetails' || this.props.pageType === 'BoatDetailsOEM' || this.props.pageType === 'EngineDetails' };
    const homePageClass = { 'home-block': !!this.context?.pages?.home?.quickSearchBlock};
    const layout = (
      <>
        <div className="page-container" id={this.props.pageType} data-testid="layout-container">
          <DataLayer pageType={this.props.pageType} brandID={ this.props.brandID } />
          <SiteMeta />
          <AppBanner
            cookies={this.props.cookies}
            pageType={this.props.pageType}
            getAppBannerHeight={this.getAppBannerHeight}/>
          <Header appBannerHeight={this.state.appBannerHeight}/>
          <div className={classnames('content nav-slide', detailClass, homePageClass)}>
            {children}
            <Footer />
            <CookiesDisclaimer cookies={this.props.cookies} />
          </div>
        </div>
        <OptionalScripts page={this.props.pageType} />
      </>
    );
    return layout;
  }
}

Default.contextType = PortalConfigContext;

Default.propTypes = {
  children: PropTypes.node,
  pageType: PropTypes.string,
  serverError: PropTypes.bool,
  cookies: PropTypes.instanceOf(Cookies),
  brandID: PropTypes.number
};
